import React, { ReactElement } from "react";
import {
  GatsbyPageProps,
  BannerHeader,
  FixedSharpImageFile,
} from "@ymcansw-camping/common";
import { graphql } from "gatsby";
import Layout from "../components/layout";

type PageNotFoundProps = GatsbyPageProps<{
  readonly bannerHeaderImage: FixedSharpImageFile;
}>;

export default function PageNotFound({
  data,
}: PageNotFoundProps): ReactElement<PageNotFoundProps> {
  return (
    <Layout meta={{ title: "Page not found", description: "" }}>
      <BannerHeader title="Page Not Found" image={data.bannerHeaderImage} />
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="content">
                <p>The page you&apos;re looking for doesn&apos;t exist.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query PageNotFoundQuery {
    bannerHeaderImage: file(
      relativePath: { eq: "img/group-accommodation-bg.jpg" }
    ) {
      ...GeneralPageLayout_bannerHeaderImage
    }
  }
`;
